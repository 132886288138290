import React from "react";

const Contact = () => {
    return (

        <div className="contact-full">
            <h1>
                Contact us at naturosynth@gmail.com
            </h1>
        </div>
    );
};

export default Contact;
